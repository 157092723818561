import React from 'react';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { format } from 'date-fns';
import { useLocalStorageState } from '../../../hooks/useLocalStorageState';
import { navigate } from '@reach/router';
import messages from './messages';
import { translateRoute } from '../../../utils/routes';
import routes from '../../../messages/routes';
// Components
import Container from '../../../components/Container';
import Main from '../../../components/Main';
import Card from '../../../components/Card';
import Airport from '../../../components/Airport';

// Icons
import SubTitleWithIcon from '../../PersonalInfo/SubTitleWithIcon';
import PlaneIconSVG from '../../../assets/icons/plane-icon.inline.svg';
import Addicon from '../../../assets/icons/add-icon.inline.svg';

// Models
import { FlightModel } from '../../../services/resources/claimGetters/filterFlightRoute';
import { CustomerFlightModel } from '../../../models/CustomerFlightModel';
import { ValidationFlightModel } from '../../../models/ValidationFlightModel';

interface AccountFlightsInterface {
  type: string;
  flightsData: FlightModel[];
  claim: any;
  showAddFlight: boolean;
}

const BOOKED = 'booked';

const AccountFlights = (props: AccountFlightsInterface) => {
  const [, setStorageValues] = useLocalStorageState('alternativeFlightCount');

  const { type, flightsData, claim, showAddFlight } = props;
  const { formatMessage } = useIntl();

  const goToAlternativeFligths = () => {
    if (type === BOOKED) {
      const routing = `/${translateRoute(routes.account)}/${translateRoute(
        routes.claim
      )}/${claim?.claim_number}/${translateRoute(
        routes.flightItinerary
      )}/${translateRoute(routes.connectingFlight)}`;

      navigate(routing, {
        state: {
          type: 'accountFlights',
          flight: 'connecting',
          claimId: claim?.id,
          claimNumber: claim?.claim_number,
        },
      });
    } else {
      setStorageValues({
        alternativeFlight: 'yes',
        alternative_flights_count: 1,
      });
      const routing = `/${translateRoute(routes.account)}/${translateRoute(
        routes.claim
      )}/${claim?.claim_number}/${translateRoute(
        routes.flightItinerary
      )}/${translateRoute(routes.replacementFlight)}`;
      navigate(routing, {
        state: {
          type: 'accountFlights',
          flight: 'replacement',
          claimId: claim?.id,
          claimNumber: claim?.claim_number,
        },
      });
    }
  };

  return (
    <Container>
      <Main>
        <Card
          onlyDesktop={true}
          style={{
            padding: !isMobile ? '1.875rem' : 0,
            marginBottom: '25px',
          }}
        >
          <Card onlyMobile={true}>
            <SubTitleWithIcon
              as="div"
              className="border-b pb-5 border-gray-200 mb-20"
              icon={null}
            >
              <div className="flex min-w-full flex-row justify-center sm:justify-between">
                <p className="text-lg font-bold mt-0">
                  {formatMessage(
                    type === BOOKED
                      ? messages.bookedFlightsText
                      : messages.alternativeFlightsText
                  )}
                </p>
              </div>
            </SubTitleWithIcon>
            <div className="flex-wrap justify-between items-center">
              {flightsData?.map((flight: FlightModel) => {
                const customerFlight = flight as CustomerFlightModel;
                const validationFlight = flight as ValidationFlightModel;

                const flightId = customerFlight?.id ??
                  validationFlight?.flight?.id;

                const flightDate = customerFlight?.flight_date ??
                  validationFlight?.flight?.scheduled_gate_departure_date;

                const flightCode = customerFlight?.flight_code
                  ?? `${validationFlight?.flight?.airline_code} ${validationFlight?.flight?.flight_number}`;

                const flightDepartureAirport = customerFlight?.departureAirport ??
                  validationFlight?.flight?.departureAirport;

                const flightArrivalAirport = customerFlight?.arrivalAirport ??
                  validationFlight?.flight?.arrivalAirport;

                const flightStatus = customerFlight?.flight_status ??
                  validationFlight?.flight?.flight_status;

                const operatingAirline = customerFlight?.airline?.name ?? validationFlight?.flight?.airline?.name;

                return (
                  <div key={flightId} className="flex">
                    <div className="flex" style={{ width: '90%' }}>
                      <div
                        style={
                          isMobile ? { width: '50%', textAlign: 'center' } : {}
                        }
                      >
                        {!isMobile ? (
                          <h2
                            className="sm:mt-30 mb-20 text-root sm:text-lg relative"
                            style={{ fontSize: '20px' }}
                          >
                            <Airport airport={flightDepartureAirport} />
                          </h2>
                        ) : (
                          <h3>{flightDepartureAirport?.iata}</h3>
                        )}
                        <>
                          {format(
                            new Date(
                              flightDate
                            ),
                            'd MMMM yyyy'
                          )}
                        </>
                      </div>
                      <PlaneIconSVG className="sm:mt-35 mx-8 inline-block w-25 h-25 fill-primary" />
                      <div
                        style={
                          isMobile ? { width: '50%', textAlign: 'center' } : {}
                        }
                      >
                        {!isMobile ? (
                          <h2
                            className="sm:mt-30 mb-20 text-root sm:text-lg relative"
                            style={{ fontSize: '20px' }}
                          >
                            <Airport airport={flightArrivalAirport} />
                          </h2>
                        ) : (
                          <h3>{flightArrivalAirport?.iata}</h3>
                        )}
                        {flightDate ? (
                          <>
                            {format(
                              new Date(
                                flightDate
                              ),
                              'd MMMM yyyy'
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="flex-col"
                      style={!isMobile ? { width: '25%' } : {}}
                    >
                      <div>
                        <h1
                          className="mb-5 sm:mt-30 mx-10 sm:mx-20 lg:mx-0 text-center sm:text-center"
                          style={isMobile ? { fontSize: '20px' } : {}}
                        >
                          {flightCode}
                        </h1>
                      </div>
                      {flightStatus && (
                        <div
                          className="button-small button"
                          style={{
                            backgroundColor: '#D8F5DF',
                            color: '#269C74',
                            border: 'none',
                            width: !isMobile ? '180px' : '95px',
                          }}
                        >
                          <span style={isMobile ? { fontSize: '11px' } : {}}>
                            {formatMessage(messages[flightStatus])}
                          </span>
                        </div>
                      )}
                      <div
                        className="font-semibold text-center sm:text-center"
                        style={isMobile ? { fontSize: '14px' } : {}}
                      >
                        <span>{operatingAirline}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {showAddFlight && (
              <div
                className="border-gray-200 border-t mt-25"
                style={{ cursor: 'pointer' }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => goToAlternativeFligths()}
              >
                <div className="flex justify-center items-center">
                  <Addicon
                    viewBox="0 0 15 15"
                    height="18"
                    width="18"
                    className="mr-15 sm:mr-5 mt-25 fill-primary"
                  />
                  <p style={{ color: '#269C74' }}>
                    {type === BOOKED
                      ? formatMessage(messages.addConnectedFlights)
                      : formatMessage(messages.addAlternativeFlight)}
                  </p>
                </div>
              </div>
            )}
          </Card>
        </Card>
      </Main>
    </Container>
  );
};

export default AccountFlights;
