import { defineMessages } from 'react-intl';

export default defineMessages({
  requirementsMainTitle: {
    id: 'cc4.claim.documents.requirements-main-title',
    defaultMessage: 'Your missing documents',
  },
  uploadedDocumentsTitle: {
    id: 'cc4.claim.documents.uploaded-documents-title',
    defaultMessage: 'Uploaded documents',
  },
  requirementMissingStatus: {
    id: 'cc4.claim.documents.requirement-missing-status',
    defaultMessage: 'Missing',
  },
  requirementRejectedStatus: {
    id: 'cc4.claim.documents.requirement-rejected-status',
    defaultMessage: 'Rejected',
  },
  addDocumentButtonText: {
    id: 'cc4.claim.documents.add-document-button-text',
    defaultMessage: 'Add',
  },
  signDocumentButtonText: {
    id: 'cc4.claim.documents.sign-document-button-text',
    defaultMessage: 'Sign now',
  },
  passengerColumnTitle: {
    id: 'cc4.claim.documents.passenger-column-title',
    defaultMessage: 'Passenger',
  },
  typeColumnTitle: {
    id: 'cc4.claim.documents.document-type-column-title',
    defaultMessage: 'Type',
  },
  filenameColumnTitle: {
    id: 'cc4.claim.documents.filename-column-title',
    defaultMessage: 'File name',
  },
  statusColumnTitle: {
    id: 'cc4.claim.documents.status-column-title',
    defaultMessage: 'Status',
  },
  fileUploadedStatus: {
    id: 'cc4.claim.documents.status.file-uploaded-status',
    defaultMessage: 'Uploaded',
  },
  documentSignedStatus: {
    id: 'cc4.claim.documents.status.document-signed-status',
    defaultMessage: 'Signed',
  },
  otherDocumentsTitle: {
    id: 'cc4.claim.documents.other-documents-title',
    defaultMessage: 'Other documents',
  },
  otherDocumentsDescription: {
    id: 'cc4.claim.documents.other-documents-description',
    defaultMessage: 'Other Documents Description',
  },
  addMoreFilesButton: {
    id: 'cc4.claim.documents.add-more-files-button',
    defaultMessage: 'Add more files',
  },
  sharePassengerTitle: {
    id: 'cc4.claim.documents.share-title',
    defaultMessage:
      'Share requirements with passenger to speed-up your compensation',
  },
  sharePassengerSendMailButtonTitle: {
    id: 'cc4.claim.documents.send-mail-button-title',
    defaultMessage: 'Send mail',
  },
  sharePassengerSendMailMobileButtonTitle: {
    id: 'cc4.claim.documents.send-mail-mobile-button-title',
    defaultMessage: 'Share',
  },
  sharePassengerCopyLinkButtonTitle: {
    id: 'cc4.claim.documents.copy-link-button-title',
    defaultMessage: 'Copy link',
  },
  sharePassengerCopiedLinkButtonTitle: {
    id: 'cc4.claim.documents.copied-link-button-title',
    defaultMessage: 'Copied',
  },
  sharePassengerMailSubjectText: {
    id: 'cc4.claim.documents.mail-subject-text',
    defaultMessage: 'Complete our {claim_airline_name} claim',
  },
  sharePassengerMailBodyText: {
    id: 'cc4.claim.documents.mail-body-text',
    defaultMessage:
      'Hi {passenger_fullname}, \n\n I am working on completing our claim to be {product_type, select, flight_compensation {compensated for the disruption of our flight with {claim_airline_name}} ticket_refund {reimbursed for the cancellation/schedule change of our flight with {claim_airline_name}} other {}}.\n\n The claim is being processed by {claim_site_name}, but they require a signed power of attorney form to correspond with {claim_airline_name}. Could you please click on the link below and sign the power of attorney form? Instructions for signing are provided on the page itself. \n\n {link} \n\n Thank you, \n {claim_customer_full_name}',
  },
  sharePassengerShareFunctionNotSupported: {
    id: 'cc4.claim.documents.share-passenger-share-function-not-supported',
    defaultMessage: 'Web share is currently not supported on this browser',
  },
});
